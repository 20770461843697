import momemt from 'moment';
import 'magnific-popup'
jQuery( document ).ready(function($) {
  //lightbox
  // $.magnificPopup.open({
  //   items: {
  //     src: '#popup-onload'
  //   },
  //   fixedContentPos: true,
  //   type: 'inline'
  // })
  
  //Redirect to Tour
  $('.menu a').removeClass('active')
  $('.menu a:nth-child(4)').addClass('active')
  $('main section').hide()
  $('#tour').show()

  // Mobile menu
  $('.menu-trigger').click(function(){
    $(this).toggleClass('active')
    $('.menu-container, #footer').toggleClass('active')
  })
  $('.menu a').click(function(){
    $('.menu-trigger').removeClass('active')
    $('.menu-container, #footer').removeClass('active')
  })
  $('.menu .internal').click(function(e){
    e.preventDefault()
    const theTarget = $(this).attr('href')
    $('.menu a').removeClass('active')
    $(this).addClass('active')
    $('main section').hide()
    $(theTarget).show()
  })

  $('.popup-cta').click(function(e){
    e.preventDefault()
    $('.mfp-wrap, .mfp-bg, main section').hide()
    $('#tour').show()
  })
  
  // Videos
  $(".thumb a").hover(
    function() {
      $(this).children("video")[0].play();
    }, function() {
      $(this).children("video")[0].pause();
      $(this).children("video")[0].currentTime = 0;
  });
  // Newsletter
  // $("#colter-wall-newsletter-sign-up").on("submit", function (e) {
  //   // Prevent default browser actions on form submission.
  //   e.preventDefault();
  //   // Get all the form user submitted data and get it ready to be sent.
  //   var data = $(this).serialize();
  //   // POST all the data to the SMF submission endpoint URL.
  //   $.ajax({
  //     type: "POST",
  //     url: "https://subs.sonymusicfans.com/submit",
  //     dataType: "json",
  //     data: data,
  //     xhrFields: {
  //       withCredentials: false
  //     },
  //     success: function (data) {
  //       $('#colter-wall-newsletter-sign-up').hide()
  //       $('.form-alert.error').hide()
  //       $('.form-alert.success').show()
  //     },
  //     error: function (err) {
  //       $('.form-alert.error').show()
  //     }
  //   });
  // });
  // Events
  // $.ajax( {
  //   url: 'https://rest.bandsintown.com/artists/Colter%20Wall/events?app_id=45PRESS_COLTER_WALL',
  //   method: 'GET',
  //   dataType: 'json',
  //   error: () => {
  //     alert( 'Error fetching events!' );
  //   },
  //   success: data => {
  //   const events = $( '#events' );
  //   let html = '';
  //   if ( data.length ) {
  //     for ( let event of data ) {
  //       let location = event.venue.city + ', ' + event.venue.region;
  //       if ( location === ', ' ) {location = '';}
  //       if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
  //       html += '<div class="event">';
  //       html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
  //       html += '<div class="event-info">';
  //       html += '<div class="event-location">' + location + '</div>';
  //       html += '<div class="event-venue">' + event.venue.name + '</div>';
  //       html += '</div>';
  //       html += '<div class="event-links">';
  //       for ( let offer of event.offers ) {
  //         html += '<a href="' + offer.url + '" target="_blank" class="btn">' + offer.type + '</a>';
  //       }
  //       html += '</div>';
  //       html += '</div>';
  //     }
  //     events.html( html );
  //     } else {
  //       events.html( 'No upcoming events.' );
  //     }
  //   }
  // });
  $('.apple-trigger').click(function(e){
    e.preventDefault()
    $('.popup.apple').addClass('active')
    // Create a new SMEAppleMusic object with your parameters.
    var sm = new SMEAppleMusic({
      ae: {
        ae: "7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd",
        brand_id: "4024194",
        segment_id: "1847033",
        activities: '{"actions":{"presave":112235},"mailing_list_optins":{"a0S0800000g9sZBEAY":112236}}'
      },
      am: {
        dev_token: "",
        save_mode: "library",
        custom_playlist_name: "",
        new_apple_presave: true,
        resources: {
          playlists: [],
          albums: []
        }
      },
      sf: {
        form: "289986",
        default_mailing_list: ""
      },
      smf: {
        campaign_id: "289986",
        campaign_key: "58c8d74004fd428ab231b5ec09769c9a"
      }
    });
    document.getElementById("apple_music_form").addEventListener("submit", function (e) {
      e.preventDefault();
      // Grab the email address value.
      var email = document.getElementById("apple_music_email_address").value;
      // Pass the email address and opt in status
      // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
      sm.doActions(email, false, ["a0S0800000g9sZBEAY"]).then(function (res) {
        // Complete - handle your logic here.
        $('.popup.apple').removeClass('active')
        $('.popup.thankyou').addClass('active')
      }).catch(function (err) {
        // Error - handle your logic here.
      });
    });
  })
  $('.popup .close').click(function(){
    $(this).parent().parent().parent().removeClass('active')
  })
  // Thankyou
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const state = urlParams.get('state')
  if(state) {
    $('.popup.thankyou').addClass('active')
  }
  // setTimeout(() => {
  //   $('.popup.banner').addClass('active')
  // }, "500");
});